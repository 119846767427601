import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { Button, Modal } from 'antd'; // Importing Button and Modal from Ant Design

const DirectorMessage = () => {
  const [directorData, setDirectorData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for controlling modal visibility

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const fetchDirectorData = async () => {
      try {
        const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/director');
        setDirectorData(response.data[0]);
      } catch (error) {
        console.error('Error fetching director data:', error);
      }
    };

    fetchDirectorData();
  }, []);

  const truncate = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const showModal = () => {
    setIsModalVisible(true); // Open modal
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  if (!directorData) {
    return <div className="flex items-center justify-center h-[50vh]">Loading...</div>;
  }

  return (
    <>
      <div className="w-full h-auto bg-white shadow-md rounded-lg overflow-hidden flex flex-col lg:flex-row">
        <div data-aos="fade-up" className="flex flex-col lg:flex-row w-full">
          <div className="lg:w-1/2 flex flex-col justify-between p-1 sm:p-2 lg:p-4">
            <div className="flex-grow">
              <h2 className="text-lg lg:text-xl font-bold mb-1">{directorData.title}</h2>
              <p className="text-sm lg:text-lg text-gray-700 mb-1 whitespace-pre-line">
                {truncate(directorData.description, 1500)} {/* Show truncated content */}
              </p>
              <Button type="primary" onClick={showModal}>
                Read More
              </Button> {/* Read More button */}
              <div className="flex-col mt-2">
                <p className="text-sm lg:text-xl font-semibold">{directorData.ceoName}</p>
                <p className="text-sm lg:text-md text-gray-500">{directorData.ceoPosition}</p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 flex items-center justify-center p-1 sm:p-2 lg:p-4">
            <img
              src={`https://bolesubadmin.api.ethioalert.com/${directorData.image}`}
              alt={directorData.ceoName}
              className="w-full h-full object-cover rounded-lg"
              style={{ objectFit: 'cover' }} // Ensures the image covers the area while maintaining aspect ratio
            />
          </div>
        </div>
      </div>

      {/* Ant Design Modal for full content */}
      <Modal
        title={directorData.title}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800} // Set the modal width to make it more spacious
      >
        <p className="text-lg text-gray-700 whitespace-pre-line">{directorData.description}</p>
        <div className="flex-col mt-4">
          <p className="text-lg font-semibold">{directorData.ceoName}</p>
          <p className="text-md text-gray-500">{directorData.ceoPosition}</p>
        </div>
      </Modal>
    </>
  );
};

export default DirectorMessage;
