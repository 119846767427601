import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './NewsCarousel.css'; // Custom styling file

const NewsCarousel = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/news');
        const sortedNews = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNews(sortedNews.slice(0, 10));
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  const truncate = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    adaptiveHeight: true,
    arrows: false, // Hide the default arrows
  };

  return (
    <div className="news-carousel-container">
      <Slider {...settings}>
        {news.map((item) => (
          <div key={item._id} className="news-item">
            <div className="news-content-container">
              <div className="news-image-container">
                <img src={`https://bolesubadmin.api.ethioalert.com/${item.image}`} alt={item.title} className="news-image rounded-xl" />
              </div>
              <div className="news-content">
                <h2 className="news-title lg:text-3xl font-bold">{truncate(item.title, 100)}</h2>
                {/* <p className="news-description lg:text-2xl">{truncate(item.description, 500)}</p> */}
                {/* <p className="news-date">{new Date(item.date).toLocaleDateString()}</p> */}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewsCarousel;
