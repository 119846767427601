import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LOGO from "../assets/BOLELOGO.png";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import { Select, Dropdown, Menu } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
  }, []);

  const managementMenu = (
    <Menu>
      <Menu.Item key="1">
        <NavLink
          to="/management/chief-executive"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/chief-executive")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Chief Executive")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink
          to="/management/cabinets"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/cabinets")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Cabinets")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink
          to="/management/structure"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/structure")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Structure")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink
          to="/management/woredas"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/woredas")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Woredas")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink
          to="/management/offices"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/offices")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Offices")}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const announcementMenu = (
    <Menu>
      <Menu.Item key="1">
        <NavLink
          to="/announcement/tender"
          className={`text-gray-800 ${
            location.pathname.startsWith("/announcement/tender")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Tender")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink
          to="/announcement/vacancy"
          className={`text-gray-800 ${
            location.pathname.startsWith("/announcement/vacancy")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Vacancy")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink
          to="/announcement/event"
          className={`text-gray-800 ${
            location.pathname.startsWith("/announcement/event")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
        >
          {t("Event")}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`sticky top-0 z-50 bg-[#0a4275] ${
        isScrolled ? "shadow-lg" : "shadow-md"
      }`}
    >
      <header
        className={`flex items-center justify-between py-2 px-4 sm:px-10 font-[sans-serif] tracking-wide transition-all duration-300 ${
          isScrolled ? "py-2" : "py-4"
        }`}
      >
        <div className="flex items-center justify-between w-full gap-5">
          <NavLink to="/" className="flex items-center">
            <img
              src={LOGO}
              alt="logo"
              className={`transition-all duration-300 ${
                isScrolled ? "w-16 h-16" : "w-20 h-20"
              }`}
              data-aos="zoom-in"
            />
            <span className="hidden md:inline text-white font-bold px-2 text-xl md:text-2xl">
              {t("Bole Subcity")}
            </span>
          </NavLink>

          {/* Hamburger Icon for Mobile */}
          <button onClick={toggleMenu} className="lg:hidden">
            <svg
              className="w-7 h-7 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>

          {/* Mobile Menu */}
          <div className={`lg:hidden ${isMenuOpen ? "block" : "hidden"}`}>
            <ul className="flex flex-col items-start p-6 space-y-4 bg-white shadow-lg fixed inset-0 z-40 w-64">
              <li className="w-full text-right">
                <button onClick={closeMenu} className="text-black text-xl">
                  &times;
                </button>
              </li>
              {["Home", "About", "News", "Gallery", "Contact", "Compliant"].map(
                (item, index) => (
                  <li key={index} className="w-full border-b border-gray-300">
                    <NavLink
                      to={`/${item === "Home" ? "" : item.toLowerCase()}`}
                      activeClassName="text-[#007bff]"
                      className="block py-3 px-3 text-gray-700 font-semibold text-lg"
                      onClick={closeMenu}
                    >
                      {t(item)}
                    </NavLink>
                  </li>
                )
              )}
              <li
                className={`w-full border-b border-gray-300 ${
                  location.pathname.startsWith("/management")
                    ? "bg-gray-200"
                    : ""
                }`}
              >
                <Dropdown
                  overlay={managementMenu}
                  trigger={["hover"]}
                  className="w-full"
                >
                  <a className="block py-3 px-3 text-gray-700 font-semibold text-lg cursor-pointer hover:bg-gray-200 transition-colors">
                    {t("Management")} <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                  </a>
                </Dropdown>
              </li>
              <li
                className={`w-full border-b border-gray-300 ${
                  location.pathname.startsWith("/announcement")
                    ? "bg-gray-200"
                    : ""
                }`}
              >
                <Dropdown
                  overlay={announcementMenu}
                  trigger={["hover"]}
                  className="w-full"
                >
                  <a className="block py-3 px-3 text-gray-700 font-semibold text-lg cursor-pointer hover:bg-gray-200 transition-colors">
                    {t("Announcement")} <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                  </a>
                </Dropdown>
              </li>
              <li className="w-full border-b border-gray-300">
                <NavLink
                  to="/contact"
                  activeClassName="text-[#007bff]"
                  className="block py-3 px-3 text-gray-700 font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Contact")}
                </NavLink>
              </li>
              <li className="w-full border-b border-gray-300">
                <NavLink
                  to="/compliant"
                  activeClassName="text-[#007bff]"
                  className="block py-3 px-3 text-gray-700 font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Compliant")}
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Regular Menu - Hidden on Small Screens */}
          <div className="hidden lg:flex items-center space-x-6">
            {["Home", "About", "News", "Gallery"].map((item, index) => (
              <NavLink
                key={index}
                to={`/${item === "Home" ? "" : item.toLowerCase()}`}
                className={({ isActive }) =>
                  isActive ? "text-yellow-500 font-bold" : "text-white"
                }
              >
                {t(item)}
              </NavLink>
            ))}
            {/* <Dropdown overlay={managementMenu} trigger={['hover']} className="relative cursor-pointer">
              <a className={`text-white font-bold hover:text-yellow-300 transition-colors ${location.pathname.startsWith("/management") ? "text-yellow-500" : ""}`}>
                {t("Management")}
              </a>
            </Dropdown> */}
            <Dropdown
              overlay={managementMenu}
              trigger={["hover"]}
              className="relative cursor-pointer"
            >
              <a
                className={`text-white font-bold hover:text-yellow-300 transition-colors ${
                  location.pathname.startsWith("/management")
                    ? "text-yellow-500"
                    : ""
                }`}
              >
                {t("Management")}{" "}
                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </a>
            </Dropdown>

            <Dropdown
              overlay={announcementMenu}
              trigger={["hover"]}
              className="relative cursor-pointer"
            >
              <a
                className={`text-white font-bold hover:text-yellow-300 transition-colors ${
                  location.pathname.startsWith("/announcement")
                    ? "text-yellow-500"
                    : ""
                }`}
              >
                {t("Announcement")} <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </a>
            </Dropdown>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "text-yellow-500 font-bold" : "text-white"
              }
            >
              {t("Contact")}
            </NavLink>
            <NavLink
              to="/compliant"
              className={({ isActive }) =>
                isActive ? "text-yellow-500 font-bold" : "text-white"
              }
            >
              {t("Compliant")}
            </NavLink>
          </div>

          {/* Language Selector */}
          <div className="flex items-center space-x-2">
            <span className="text-sm font-bold text-white">{t("LN")}</span>
            <Select
              defaultValue={i18n.language}
              value={i18n.language}
              onChange={changeLanguage}
              className="text-sm rounded-full font-bold bg-blue-600 text-white hover:bg-blue-700 focus:border-blue-800"
              dropdownClassName="bg-white border border-blue-600 rounded-lg"
            >
              {[
                { value: "am", label: t("Amharic") },
                { value: "en", label: t("English") },
                { value: "or", label: t("Afaan Oromo") },
                { value: "sm", label: t("Somali") },
                { value: "ch", label: t("Chinese") },
                { value: "ar", label: t("Arabic") },
                { value: "sp", label: t("Spanish") },
              ].map((option) => (
                <Option
                  key={option.value}
                  className="p-2 hover:bg-blue-700"
                  value={option.value}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
