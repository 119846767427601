import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, message, Table, Modal, Select } from 'antd';

const { Option } = Select;

const UserManager = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [formMode, setFormMode] = useState('add'); // 'add' or 'edit'

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (formMode === 'add') {
        await axios.post('https://bolesubadmin.api.ethioalert.com/api/users/register', values);
        message.success('User registered successfully!');
      } else {
        await axios.patch(`https://bolesubadmin.api.ethioalert.com/api/users/${currentUser._id}`, values);
        message.success('User updated successfully!');
      }
      form.resetFields();
      setIsModalVisible(false);
      fetchUsers();
    } catch (error) {
      console.error('Error submitting user data:', error);
      message.error('Failed to save user. Please try again.');
    }
  };

  const handleEdit = (record) => {
    form.setFieldsValue({
      username: record.username,
      role: record.role,
    });
    setCurrentUser(record);
    setFormMode('edit');
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bolesubadmin.api.ethioalert.com/api/users/${id}`);
      message.success('User deleted successfully');
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      message.error('Failed to delete user. Please try again.');
    }
  };

  const handlePasswordReset = async (id) => {
    try {
      await axios.post(`https://bolesubadmin.api.ethioalert.com/users/reset-password/${id}`);
      message.success('Password reset successfully');
    } catch (error) {
      console.error('Error resetting password:', error);
      message.error('Failed to reset password. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentUser(null);
    setFormMode('add');
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
            Edit
          </Button>
          <Button type="default" onClick={() => handleDelete(record._id)} style={{ marginRight: 8 }}>
            Delete
          </Button>
          <Button type="default" onClick={() => handlePasswordReset(record._id)}>
            Reset Password
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: 20 }}>
        Add User
      </Button>
      <Table columns={columns} dataSource={users} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title={formMode === 'add' ? 'Register User' : 'Update User'}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Please input the username!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: formMode === 'add', message: 'Please input the password!' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please select a role!' }]}>
            <Select>
              <Option value="compliant">Compliant</Option>
              <Option value="admin">Admin</Option>
              <Option value="communication">Communication</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManager;
