import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const GalleryManager = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchGalleryImages();
  }, []);

  const fetchGalleryImages = async () => {
    try {
      const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/gallery-images');
      setGalleryImages(response.data);
    } catch (error) {
      console.error('Error fetching gallery images:', error);
    }
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
    setImageFiles(fileList.map(file => file.originFileObj));
  };

  const handleUpdateFileChange = (e) => {
    setImageFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('description', values.description);
    imageFiles.forEach(file => formData.append('images', file));

    try {
      if (editId) {
        await axios.patch(`https://bolesubadmin.api.ethioalert.com/api/gallery-images/${editId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Gallery image updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://bolesubadmin.api.ethioalert.com/api/gallery-images', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Gallery image added successfully!');
      }
      form.resetFields();
      setFileList([]);
      setImageFiles([]);
      fetchGalleryImages();
    } catch (error) {
      console.error('Error submitting gallery image data:', error);
      message.error(editId ? 'Failed to update gallery image. Please try again.' : 'Failed to add gallery image. Please try again.');
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const formData = new FormData();
      formData.append('description', values.description);
      imageFiles.forEach(file => formData.append('images', file));

      await axios.patch(`https://bolesubadmin.api.ethioalert.com/api/gallery-images/${currentImage._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      message.success('Gallery image updated successfully');
      setIsModalVisible(false);
      setImageFiles([]);
      fetchGalleryImages();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      description: record.description,
    });
    setCurrentImage(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bolesubadmin.api.ethioalert.com/api/gallery-images/${id}`);
      message.success('Gallery image deleted successfully');
      fetchGalleryImages();
    } catch (error) {
      console.error('Error deleting gallery image:', error);
      message.error('Failed to delete gallery image. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
    setImageFiles([]);
  };

  const viewImage = (record) => {
    Modal.info({
      title: 'View Image',
      content: (
        <div>
          <p><strong>Description:</strong> {record.description}</p>
          {record.images && record.images.length > 0 ? (
            record.images.map((image, index) => (
              <img
                key={index}
                src={`https://bolesubadmin.api.ethioalert.com/${image}`}
                alt={record.description}
                style={{ width: '100%', maxHeight: '400px', marginBottom: '10px' }}
              />
            ))
          ) : (
            <p>No Image</p>
          )}
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this image?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Images',
      dataIndex: 'images',
      key: 'images',
      render: (images) => (
        images && images.length > 0 ? (
          images.map((image, index) => (
            <Image key={index} width={100} src={`https://bolesubadmin.api.ethioalert.com/${image}`} alt="Gallery Image" fallback="default-image-url" />
          ))
        ) : (
          'No Image'
        )
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewImage(record)} style={{ marginRight: 8 }}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Images">
          <Upload
            multiple
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Upload Images</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={galleryImages} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="Update Gallery Image"
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
      >
        <Form form={updateForm} layout="vertical" initialValues={currentImage}>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Update Images">
            <input type="file" multiple onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GalleryManager;
