// src/components/DataTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Button, Modal, Table, Form, Input, InputNumber, Select } from 'antd';

const { Option } = Select;

const DataTable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [form] = Form.useForm();

  const categories = [
    'House Data',
    'Health Facilities',
    'Government and Personal Buildings',
    'Buildings',
    'Gender Distribution',
    'Area'
  ];

  useEffect(() => {
    AOS.init({ duration: 1000 });

    // Fetch data from backend
    const fetchData = async () => {
      try {
        const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/data');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      const response = await axios.post('https://bolesubadmin.api.ethioalert.com/api/data', values);
      setData([...data, response.data]);
      form.resetFields();
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error creating data:', error);
    }
  };

  const handleUpdate = async (values) => {
    try {
      const response = await axios.put(`https://bolesubadmin.api.ethioalert.com/api/data/${currentData._id}`, values);
      setData(data.map(item => (item._id === currentData._id ? response.data : item)));
      form.resetFields();
      setIsModalVisible(false);
      setCurrentData(null);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bolesubadmin.api.ethioalert.com/api/data/${id}`);
      setData(data.filter(item => item._id !== id));
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const showModal = (record = null) => {
    setCurrentData(record);
    form.setFieldsValue(record || {});
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentData(null);
  };

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Value'),
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="primary" onClick={() => showModal(record)} style={{ marginRight: 8 }}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDelete(record._id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="mx-auto p-4 bg-[#0a4275]">
      <h1 className="text-3xl font-bold mb-8 text-center text-white" data-aos="fade-up">
        {t('Data Management')}
      </h1>

      <Button type="primary" onClick={() => showModal()} style={{ marginBottom: 16 }}>
        {t('Add New Data')}
      </Button>

      <Table columns={columns} dataSource={data} rowKey="_id" />

      <Modal
        title={currentData ? t('Edit Data') : t('Add New Data')}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={currentData || {}}
          onFinish={currentData ? handleUpdate : handleCreate}
        >
          <Form.Item
            name="name"
            label={t('Name')}
            rules={[{ required: true, message: t('Please input the name!') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="value"
            label={t('Value')}
            rules={[{ required: true, message: t('Please input the value!') }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name="category"
            label={t('Category')}
            rules={[{ required: true, message: t('Please select the category!') }]}
          >
            <Select>
              {categories.map(category => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentData ? t('Update') : t('Create')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DataTable;
