import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import LOGO from "../../assets/BOLELOGO.png";
import { useTranslation } from "react-i18next";

const About1 = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    const { t, i18n } = useTranslation();
    return (
        <section className="bg-gray-100  py-16 px-4 sm:px-6 lg:px-8">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
                    <div className="max-w-lg p-6" data-aos="fade-up">
                        <h2 className="text-3xl font-extrabold text-[#f5a623] sm:text-4xl">
                            {t('About Kirkos Sub-city')}
                        </h2>
                        <p className="mt-4 text-[#0a4275] text-lg">
                            {t("Kirkos Sub-city is one of the eleven sub-cities in Addis Ababa, Ethiopia's bustling capital. Located below Stephen's Church and next to Mariwot Hotel, Kirkos is a vibrant area known for its diverse community. It serves as a key governmental institution, encompassing ten woredas that provide essential public services, including education and healthcare.")}
                        </p>
                        <p className="mt-4 text-[#0a4275] text-lg">
                            {t("With a population of over 235,000 as of 2011, Kirkos Sub-city plays a crucial role in the daily lives of its residents and visitors. Its strategic location and comprehensive services highlight its significance in Addis Ababa's urban landscape. Kirkos is dedicated to community development, contributing significantly to the city's growth and advancement.")}
                        </p>
                    </div>
                    <div className="mt-12 md:mt-0" data-aos="fade-left">
                        <img 
                            src={LOGO}                            
                            alt="Kirkos Sub-city Logo" 
                            className="object-contain w-full h-[70vh] rounded-lg shadow-md" 
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About1;
