import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader'; // Import the Loader component
import Header from './Header';
import { Image } from 'antd'; // Import Ant Design Image component
import { useTranslation } from "react-i18next";

const DetailedGallery = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [galleryItem, setGalleryItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchGalleryItem = async () => {
      try {
        const response = await axios.get(`https://bolesubadmin.api.ethioalert.com/api/gallery-images/${id}`);
        setGalleryItem(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching gallery item:', error);
        setLoading(false);
      }
    };

    fetchGalleryItem();
  }, [id]);

  if (loading) return <Loader />; // Show loader while data is being fetched

  if (!galleryItem) return <div>No data found</div>; // Handle no data scenario

  // Format the date for display
  const formattedDate = new Date(galleryItem.date).toLocaleDateString();

  return (
    <div>
      <Header />
      <div className="mx-auto p-4 lg:p-8 bg-blue-50 min-h-screen">
        <button
          onClick={() => navigate('/gallery')} // Navigate to the gallery page
          className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          {t('Back to Gallery')}
        </button>
        <h1 className="text-2xl font-bold mb-4">{t('Gallery Item Details')}</h1>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-1/2">
            {/* Display the first image with Ant Design Image component */}
            <Image
              alt="Gallery item"
              src={`https://bolesubadmin.api.ethioalert.com/${galleryItem.images[0]}`}
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="md:w-1/2 flex flex-col justify-between">
            {/* Display description and date */}
            <div>
              <h2 className="text-xl font-semibold mb-2">{t('Description')}</h2>
              <p className="text-gray-700">{galleryItem.description}</p>
            </div>
            <div className="mt-4">
              <p className="text-gray-600"><strong>{t('Date Posted')}:</strong> {formattedDate}</p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">{t('Additional Images')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {galleryItem.images.slice(1).map((image, index) => (
              <div key={index} className="relative w-full h-48">
                {/* Display additional images with Ant Design Image component */}
                <Image
                  alt={`Gallery image ${index + 1}`}
                  src={`https://bolesubadmin.api.ethioalert.com/${image}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedGallery;
