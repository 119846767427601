import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Pagination, Input, Empty, Modal } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import 'antd/dist/antd.css'; // Import Ant Design styles
import { useTranslation } from "react-i18next";
const { Search } = Input;

const TenderList = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [selectedTender, setSelectedTender] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchTenders();
  }, []);

  const fetchTenders = async () => {
    try {
      const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/tenders');
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Error fetching tenders:', error);
    }
  };

  const handleSearch = (value) => {
    const filtered = data.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleView = (record) => {
    setSelectedTender(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedTender(null);
  };

  const columns = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('Image'),
      dataIndex: 'image',
      key: 'image',
      render: (image) => image ? <img src={`https://bolesubadmin.api.ethioalert.com/${image}`} alt="Tender" style={{ width: 100, height: 100, objectFit: 'cover' }} /> : 'No Image',
    },
    {
      title: t('Post Date'),
      dataIndex: 'postDate',
      key: 'postDate',
    },
    {
      title: t('Expired Date'),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <a href="#" className="text-blue-500 hover:underline" onClick={() => handleView(record)}>{t('View')}</a>
      ),
    },
  ];

  return (
    <div className="bg-[#f5f5f5] py-16 px-4 lg:px-8" data-aos="fade-up">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-[#0a4275] mb-6">{t('Tenders')}</h1>
        
        <Search
          placeholder="Search by Tender Title"
          onSearch={handleSearch}
          style={{ marginBottom: '16px' }}
          allowClear
        />
        
        {filteredData.length > 0 ? (
          <>
            <Table
              columns={columns}
              dataSource={filteredData}
              pagination={false}
              onChange={handleTableChange}
              rowClassName="hover:bg-gray-100"
              className="bg-white rounded-lg shadow-md"
              rowKey="_id"
            />
            
            <div className="flex justify-between items-center mt-4">
              <span className="text-sm text-gray-600">
                Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
              </span>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredData.length}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
                className="ant-pagination"
              />
            </div>
          </>
        ) : (
          <Empty description="No data available in table" />
        )}

        {selectedTender && (
          <Modal
            title="Tender Details"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={800}
          >
            <div>
              <h2><strong>{t('Title')}:</strong>{selectedTender.title}</h2>
              <p><strong>{t('Post Date')}:</strong> {new Date(selectedTender.postDate).toLocaleDateString()}</p>
              <p><strong>{t('Expired Date')}:</strong> {new Date(selectedTender.expiredDate).toLocaleDateString()}</p>
              <p><strong>{t('Description')}:</strong> {selectedTender.description}</p>
              {selectedTender.image && (
                <div>
                  <strong>{t('Image')}:</strong>
                  <img src={`https://bolesubadmin.api.ethioalert.com/${selectedTender.image}`} alt="Tender" style={{ width: '100%', maxHeight: 400, objectFit: 'contain' }} />
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default TenderList;
