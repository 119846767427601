import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import { FaPhone, FaFax, FaEnvelope, FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { BiSolidCheckCircle } from 'react-icons/bi';
import { AiOutlineStar, AiOutlineLock } from 'react-icons/ai';
import { MdSchool } from 'react-icons/md';
import { Divider, Card } from 'antd';
import { useTranslation } from "react-i18next";


const { Meta } = Card;

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className="bg-[#0a4275] text-white py-16 px-4 sm:px-6 lg:px-8">
            <div className="container mx-auto">
                {/* Uncomment if you want the header section */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                    <div data-aos="fade-up">
                        <h2 className="text-3xl font-bold text-[#f5a623] mb-4">{t('Vision')}</h2>
                        <p className="text-lg">
                            {t("Seeing Addis Ababa as a symbol of Africa's prosperity.")}
                        </p>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="100">
                        <h2 className="text-3xl font-bold text-[#f5a623] mb-4">{t('Mission')}</h2>
                        <p className="text-lg">
                            {t("By strengthening internal and external relations, by increasing the construction of the city's appearance, by monitoring and evaluating the performance of institutions based on results, by implementing cabinet decisions that ensure the universal benefit of the people, and by establishing good governance, by making service delivery fair, transparent, and accountable, it is to ensure the benefit of the society.")}
                        </p>
                    </div>
                </div>

                <div className="mb-12" data-aos="fade-up">
                    <h2 className="text-3xl font-bold text-[#f5a623] mb-4">{t('Core Values')}</h2>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<BiSolidCheckCircle className="text-[#0a4275] text-3xl" />}
                                title={t("Transparency and Accountability")}
                            />
                        </Card>
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<AiOutlineStar className="text-[#0a4275] text-3xl" />}
                                title={t('Honesty')}
                            />
                        </Card>
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<AiOutlineLock className="text-[#0a4275] text-3xl" />}
                                title={t('Loyalty')}
                            />
                        </Card>
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<MdSchool className="text-[#0a4275] text-3xl" />}
                                title={t('Motivation')}
                            />
                        </Card>
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<BiSolidCheckCircle className="text-[#0a4275] text-3xl" />}
                                title={t('Excellent Service')}
                            />
                        </Card>
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<AiOutlineLock className="text-[#0a4275] text-3xl" />}
                                title={t('Rule of Law')}
                            />
                        </Card>
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<AiOutlineStar className="text-[#0a4275] text-3xl" />}
                                title={t('Greater Public Interest')}
                            />
                        </Card>
                        <Card className="bg-white text-[#0a4275]" bordered={false}>
                            <Meta
                                avatar={<MdSchool className="text-[#0a4275] text-3xl" />}
                                title={t('Leading with Knowledge and Faith')}
                            />
                        </Card>
                    </div>
                </div>

                <div data-aos="fade-up">
                    <h2 className="text-3xl font-bold text-[#f5a623] mb-4">{t('Contact Us')}</h2>
                    <div className="flex flex-col md:flex-row gap-6 md:gap-12">
                        <div className="flex items-center mb-4">
                            <FaPhone className="text-[#f5a623] text-2xl mr-3" />
                            <span className="text-lg">{t('+251 115 50 69 25')}</span>
                        </div>
                        <div className="flex items-center mb-4">
                            <FaFax className="text-[#f5a623] text-2xl mr-3" />
                            <span className="text-lg">{t('Fax: 951')}</span>
                        </div>
                        <div className="flex items-center mb-4">
                            <FaEnvelope className="text-[#f5a623] text-2xl mr-3" />
                            <span className="text-lg">
                                {t('Email:')} <a href="mailto:Bolesubcity14@gmail.com" className="text-[#f5a623]">wanasiraasfetsami@aakirkossubcity.com</a>
                            </span>
                        </div>
                        <div className="flex gap-4">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-[#f5a623] hover:text-white">
                            <FaFacebook size={30} />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-[#f5a623] hover:text-white">
                            <FaTwitter size={30} />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-[#f5a623] hover:text-white">
                            <FaInstagram size={30} />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-[#f5a623] hover:text-white">
                            <FaLinkedin size={30} />
                        </a>
                    </div>
                    </div>
                 
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
