import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, Image } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

const OfficeManager = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentOffice, setCurrentOffice] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchOffices();
  }, []);

  const fetchOffices = async () => {
    try {
      const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/addoffice');
      setOffices(response.data);
    } catch (error) {
      console.error('Error fetching offices:', error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('vision', values.vision);
    formData.append('mission', values.mission);
    formData.append('coreValues', values.coreValues);
    formData.append('phoneNumber', values.phoneNumber);
    formData.append('email', values.email);
    formData.append('address', values.address);
    formData.append('floor', values.floor);
    if (fileList.length > 0) {
      formData.append('logo', fileList[0].originFileObj);
    }

    try {
      if (editId) {
        await axios.put(`https://bolesubadmin.api.ethioalert.com/api/addoffice/${editId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Office updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://bolesubadmin.api.ethioalert.com/api/addoffice', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Office added successfully!');
      }
      form.resetFields();
      setFileList([]);
      fetchOffices();
    } catch (error) {
      console.error('Error submitting office data:', error);
      message.error(editId ? 'Failed to update office. Please try again.' : 'Failed to add office. Please try again.');
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.logo = imageFile;

      const formData = new FormData();
      formData.append('name', updatedData.name);
      formData.append('vision', updatedData.vision);
      formData.append('mission', updatedData.mission);
      formData.append('coreValues', updatedData.coreValues);
      formData.append('phoneNumber', updatedData.phoneNumber);
      formData.append('email', updatedData.email);
      formData.append('address', updatedData.address);
      formData.append('floor', updatedData.floor);
      if (imageFile) formData.append('logo', updatedData.logo);

      await axios.put(`https://bolesubadmin.api.ethioalert.com/api/addoffice/${currentOffice._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      message.success('Office updated successfully');
      setIsModalVisible(false);
      fetchOffices();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      name: record.name,
      vision: record.vision,
      mission: record.mission,
      coreValues: record.coreValues,
      phoneNumber: record.phoneNumber,
      email: record.email,
      address: record.address,
      floor: record.floor,
    });
    setCurrentOffice(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bolesubadmin.api.ethioalert.com/api/addoffice/${id}`);
      message.success('Office deleted successfully');
      fetchOffices();
    } catch (error) {
      console.error('Error deleting office:', error);
      message.error('Failed to delete office. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentOffice(null);
    setImageFile(null);
  };

  const viewOffice = (record) => {
    Modal.info({
      title: 'View Office',
      content: (
        <div>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Vision:</strong> {record.vision}</p>
          <p><strong>Mission:</strong> {record.mission}</p>
          <p><strong>Core Values:</strong> {record.coreValues}</p>
          <p><strong>Phone Number:</strong> {record.phoneNumber}</p>
          <p><strong>Email:</strong> {record.email}</p>
          <p><strong>Address:</strong> {record.address}</p>
          <p><strong>Floor:</strong> {record.floor}</p>
          {record.logo && <Image src={`https://bolesubadmin.api.ethioalert.com/uploads/Offices/${record.logo}`} alt="Logo" style={{ width: '100%', maxHeight: '400px' }} />}
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this office?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Floor',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo) => (
        logo ? <Image width={100} src={`https://bolesubadmin.api.ethioalert.com/uploads/Offices/${logo}`} alt="Office Logo" /> : 'No Logo'
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewOffice(record)} style={{ marginRight: 8 }}>
            <EyeOutlined />
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            <EditOutlined />
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="vision" label="Vision" rules={[{ required: true, message: 'Please input the vision!' }]}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item name="mission" label="Mission" rules={[{ required: true, message: 'Please input the mission!' }]}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item name="coreValues" label="Core Values" rules={[{ required: true, message: 'Please input the core values!' }]}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true, message: 'Please input the phone number!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input the email!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please input the address!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="floor" label="Floor" rules={[{ required: true, message: 'Please input the floor!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Logo">
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Upload Logo</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={offices} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="Update Office"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="vision" label="Vision" rules={[{ required: true, message: 'Please input the vision!' }]}>
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item name="mission" label="Mission" rules={[{ required: true, message: 'Please input the mission!' }]}>
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item name="coreValues" label="Core Values" rules={[{ required: true, message: 'Please input the core values!' }]}>
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true, message: 'Please input the phone number!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input the email!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please input the address!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="floor" label="Floor" rules={[{ required: true, message: 'Please input the floor!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Logo">
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OfficeManager;
