import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoginForm from './LoginForm';

const AuthHandler = ({ onLogin }) => {
  const navigate = useNavigate();

  const handleLogin = async (username, password) => {
    try {
      const response = await axios.post("https://bolesubadmin.api.ethioalert.com/api/users/login", { username, password });
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role); // Save role in localStorage
        localStorage.setItem("userId", response.data.userId); // Save userId in localStorage
        console.log("THE RESPONSE IS ", response);
        console.log("THE RESPONSE IS ", response.data.userId);
        onLogin(response.data.role); // Callback to set authentication state
        navigate("/adminpage"); // Redirect to adminpage
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Invalid username or password");
    }
  };

  return <LoginForm onLogin={handleLogin} />;
};

export default AuthHandler;
