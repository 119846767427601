import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const DirectorMessageManager = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [editId, setEditId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [updateForm] = Form.useForm();

  useEffect(() => {
    fetchDirectorMessages();
  }, []);

  const fetchDirectorMessages = async () => {
    try {
      const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/director');
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching director messages:', error);
    }
  };

  const deleteDirectorMessage = async (id) => {
    try {
      await axios.delete(`https://bolesubadmin.api.ethioalert.com/api/director/${id}`);
      fetchDirectorMessages();
      message.success('Director message deleted successfully');
    } catch (error) {
      console.error('Error deleting director message:', error);
      message.error('Failed to delete director message. Please try again.');
    }
  };

  const updateDirectorMessage = async (id, updatedData) => {
    try {
      const formData = new FormData();
      formData.append('title', updatedData.title);
      formData.append('description', updatedData.description);
      formData.append('ceoName', updatedData.ceoName);
      formData.append('ceoPosition', updatedData.ceoPosition);
      if (updatedData.image) {
        formData.append('image', updatedData.image);
      }
      await axios.patch(`https://bolesubadmin.api.ethioalert.com/api/director/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchDirectorMessages();
      message.success('Director message updated successfully');
    } catch (error) {
      console.error('Error updating director message:', error);
      message.error('Failed to update director message. Please try again.');
    }
  };

  const handleUpdate = (record) => {
    updateForm.resetFields();
    setCurrentMessage({ ...record });
    setIsModalVisible(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) {
        updatedData.image = imageFile;
      }
      updateDirectorMessage(currentMessage._id, updatedData);
      setIsModalVisible(false);
      setCurrentMessage(null);
      setImageFile(null);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentMessage(null);
    setImageFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('ceoName', values.ceoName);
    formData.append('ceoPosition', values.ceoPosition);
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    try {
      let response;
      if (editId) {
        response = await axios.patch(`https://bolesubadmin.api.ethioalert.com/api/director/${editId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setEditId(null);
      } else {
        response = await axios.post('https://bolesubadmin.api.ethioalert.com/api/director', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      message.success(editId ? "Director message updated successfully!" : "Director message added successfully!");
      form.resetFields();
      setFileList([]);
      fetchDirectorMessages();
    } catch (error) {
      console.error('Error submitting director message data:', error);
      message.error(editId ? "Failed to update Director message. Please try again." : "Failed to add Director message. Please try again.");
    }
  };

  const handleImageChange = ({ fileList }) => {
    setFileList(fileList.slice(-1));
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => truncateText(text, 20),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => truncateText(text, 40),
    },
    {
      title: 'CEO Name',
      dataIndex: 'ceoName',
      key: 'ceoName',
    },
    {
      title: 'CEO Position',
      dataIndex: 'ceoPosition',
      key: 'ceoPosition',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewMessage(record)} style={{ marginRight: 8 }}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const viewMessage = (record) => {
    Modal.info({
      title: 'View Message',
      content: (
        <div>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Description:</strong> {record.description}</p>
          <p><strong>CEO Name:</strong> {record.ceoName}</p>
          <p><strong>CEO Position:</strong> {record.ceoPosition}</p>
          <p><strong>Image:</strong> <img src={`https://bolesubadmin.api.ethioalert.com/${record.image}`} alt={record.title} style={{ width: '100%', maxHeight: '400px' }} /></p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this message?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteDirectorMessage(id);
      },
    });
  };

  return (
    <div className='w-full mx-auto p-4'>
      <div className="w-full mx-auto mb-8">
        <h1 className="text-2xl font-bold mb-4 text-blue-800">Add Director Message</h1>
        <Form form={form} onFinish={handleSubmit} className="space-y-4">
          <Form.Item
            name="title"
            label="Title"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input the description!' }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item
            name="ceoName"
            label="CEO Name"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input the CEO name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ceoPosition"
            label="CEO Position"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input the CEO position!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            labelCol={{ span: 24 }}
          >
            <Upload
              listType="picture"
              beforeUpload={() => false}
              onChange={handleImageChange}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editId ? 'Update Message' : 'Add Message'}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="w-full mx-auto">
        <h1 className="text-2xl font-bold mb-4 text-blue-800">Director Messages List</h1>
        <Table
          columns={columns}
          dataSource={messages}
          rowKey="_id"
        />
      </div>
      <Modal
        title="Update Director Message"
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
        okText="Update"
        cancelText="Cancel"
      >
        <Form form={updateForm} layout="vertical" initialValues={currentMessage}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please input the description!' }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item
            name="ceoName"
            label="CEO Name"
            rules={[{ required: true, message: 'Please input the CEO name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ceoPosition"
            label="CEO Position"
            rules={[{ required: true, message: 'Please input the CEO position!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Image"
          >
            <input type="file" onChange={handleFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DirectorMessageManager;
