import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, message, Table, Modal } from 'antd';

const QuickMessageManager = () => {
  const [form] = Form.useForm();
  const [quickMessages, setQuickMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentQuickMessage, setCurrentQuickMessage] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchQuickMessages();
  }, []);

  const fetchQuickMessages = async () => {
    try {
      const response = await axios.get('https://bolesubadmin.api.ethioalert.com/api/quickMessages');
      setQuickMessages(response.data);
    } catch (error) {
      console.error('Error fetching quick messages:', error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (editId) {
        await axios.patch(`https://bolesubadmin.api.ethioalert.com/api/quickMessages/${editId}`, values);
        message.success('Quick message updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://bolesubadmin.api.ethioalert.com/api/quickMessages', values);
        message.success('Quick message added successfully!');
      }
      form.resetFields();
      fetchQuickMessages();
    } catch (error) {
      console.error('Error submitting quick message data:', error);
      message.error(editId ? 'Failed to update quick message. Please try again.' : 'Failed to add quick message. Please try again.');
    }
  };

  const handleUpdate = (record) => {
    form.setFieldsValue({
      message: record.message,
    });
    setCurrentQuickMessage(record);
    setEditId(record._id);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bolesubadmin.api.ethioalert.com/api/quickMessages/${id}`);
      message.success('Quick message deleted successfully');
      fetchQuickMessages();
    } catch (error) {
      console.error('Error deleting quick message:', error);
      message.error('Failed to delete quick message. Please try again.');
    }
  };

  const handleView = (record) => {
    setCurrentQuickMessage(record);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setCurrentQuickMessage(null);
    setEditId(null);
  };

  const columns = [
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => handleView(record)} style={{ marginRight: 8 }}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => handleDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please input the message!' }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={quickMessages} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="Update Quick Message"
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" initialValues={currentQuickMessage}>
          <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please input the message!' }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="View Quick Message"
        visible={isViewModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>Close</Button>,
        ]}
      >
        {currentQuickMessage && (
          <div>
            <p><strong>Message:</strong></p>
            <p>{currentQuickMessage.message}</p>
            <p><strong>Created At:</strong> {new Date(currentQuickMessage.createdAt).toLocaleString()}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default QuickMessageManager;
